import { render, staticRenderFns } from "./assess-list.vue?vue&type=template&id=646b4a27&scoped=true&"
import script from "./assess-list.vue?vue&type=script&lang=js&"
export * from "./assess-list.vue?vue&type=script&lang=js&"
import style0 from "./assess-list.vue?vue&type=style&index=0&id=646b4a27&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "646b4a27",
  null
  
)

export default component.exports